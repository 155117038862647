<script>
  import PageDataSidebar from '@/components/shared_components/page_data/PageDataSidebar';

  export default {
    extends: PageDataSidebar,
    props: {
      chartsList: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      activeTab() { return this.$route.query.view || ''; },
      navLinksList() {
        return this.chartsList.map((chart) => {
          return {
            title: chart.name,
            route: {name: 'BuyerStatisticsChart', query: {view: this.activeTab}, params: {chartId: chart.id}}
          };
        });
      },
    }
  };
</script>
