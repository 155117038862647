(<template>
  <page-data-section class="filter-bar">
    <div class="filter-bar__fields">
      <sk-multiselect v-if="showDepartmentsSelect"
                      :items-list="computedDepartmentsList"
                      :preselected-value="departments"
                      :description="departmentsText"
                      :default-value="departmentsText"
                      class="filter-bar__field"
                      @checkboxeschanged="setDepartments" />
      <sk-multiselect v-if="showBookersSelect"
                      :items-list="computedBookersList"
                      :preselected-value="bookers"
                      :description="bookersText"
                      :default-value="bookersText"
                      class="filter-bar__field"
                      @checkboxeschanged="setBookers" />
      <sk-multiselect v-if="showBookedPerformsSelect"
                      :items-list="computedBookedPerforms"
                      :preselected-value="interpreters"
                      :description="interpretersText"
                      :default-value="interpretersText"
                      class="filter-bar__field"
                      @checkboxeschanged="setInterpreters" />
      <sk-multiselect v-if="showStatusesSelect"
                      :items-list="computedStatuses"
                      :preselected-value="statuses"
                      :description="statusText"
                      :default-value="statusText"
                      class="filter-bar__field"
                      @checkboxeschanged="setStatuses" />
      <sk-multiselect v-if="showTypesSelect"
                      :items-list="computedTypes"
                      :preselected-value="types"
                      :description="typeText"
                      :default-value="typeText"
                      class="filter-bar__field"
                      @checkboxeschanged="setTypes" />
      <sk-multiselect v-if="showLanguagesSelect"
                      :items-list="languagesList"
                      :preselected-value="languages"
                      :description="languagesText"
                      :default-value="languagesText"
                      class="filter-bar__field"
                      @checkboxeschanged="setLanguages" />
      <div v-else
           class="filter-bar__field filter-bar__field--wrapper">
        <sk-select :items-list="languagesList"
                   :preselected-value="sourceLanguage"
                   :description="languageFromText"
                   :default-value="languageFromText"
                   class="filter-bar__field-item"
                   @csvaluechanged="setSourceLanguage" />
        <sk-select :items-list="languagesList"
                   :preselected-value="targetLanguage"
                   :description="languageToText"
                   :default-value="languageToText"
                   class="filter-bar__field-item"
                   @csvaluechanged="setTargetLanguage" />
      </div>
      <monthly-range-picker v-for="(date, index) in datesList"
                            :key="index"
                            :description-from="date.descriptionFrom"
                            :description-to="date.descriptionTo"
                            :from="date.startDate"
                            :to="date.endDate"
                            class="filter-bar__field"
                            @startdatechanged="date.startDate.dateChanged"
                            @enddatechanged="date.endDate.dateChanged" />
    </div>
  </page-data-section>
</template>)

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import MonthlyRangePicker from '@/components/statistics/MonthlyRangePicker';

  export default {
    components: {
      'monthly-range-picker': MonthlyRangePicker,
      'page-data-section': PageDataSection
    },
    computed: {
      ...mapState('StatisticsStore', {
        bookedInterpreters: (state) => state.bookedInterpreters || [],
        bookedTranslators: (state) => state.bookedTranslators || [],
        filterValues: (state) => state.filterValues,
        departmentsList: ({departmentsList}) => departmentsList || [],
        bookersList: ({bookersList}) => bookersList || [],
        departments: ({filterValues}) => filterValues.departments,
        bookers: ({filterValues}) => filterValues.bookers,
        interpreters: ({filterValues}) => filterValues.interpreters,
        types: ({filterValues}) => filterValues.types,
        statuses: ({filterValues}) => filterValues.statuses,
        languages: ({filterValues}) => filterValues.languages,
        sourceLanguage: ({filterValues}) => filterValues.sourceLanguage,
        targetLanguage: ({filterValues}) => filterValues.targetLanguage,
        startDate: ({filterValues}) => filterValues.startDate,
        endDate: ({filterValues}) => filterValues.endDate
      }),
      datesList() {
        return {
          dateRange: {
            ...this.getRangeObject({
              descriptionFrom: this.dateFromText,
              descriptionTo: this.dateToText,
              startDate: this.startDate,
              endDate: this.endDate,
              mutationFunc: this.setDateRange
            })
          }
        };
      },
      languagesList() {
        const fullList = this.$store.state.selectsData.language;
        const assignmentList = fullList?.filter((lang) => lang.id != 3);
        return this.isInterpretationView ? assignmentList : fullList || [];
      },
      departmentsText() { return this.$gettext('Department(s)'); },
      bookersText() { return this.$gettext('Booker(s)'); },
      interpretersText() { return this.isInterpretationView ? this.$gettext('Interpreter(s)') : this.$gettext('Translator(s)'); },
      typeText() { return this.isInterpretationView ? this.$gettext('Assignment type') : this.$gettext('Project type'); },
      statusText() { return this.$gettext('Status'); },
      languagesText() { return this.$gettext('Languages'); },
      languageFromText() { return this.$pgettext('translation', 'Language from'); },
      languageToText() { return this.$gettext('Language to'); },
      dateFromText() { return this.$gettext('Date from'); },
      dateToText() { return this.$gettext('Date to'); },
      isInterpretationView() { return this.$route.query.view === 'interpretation'; },
      showDepartmentsSelect() { return !!this.departmentsList.length; },
      showBookersSelect() { return !!this.bookersList.length; },
      showBookedPerformsSelect() { return this.computedBookedPerforms && this.computedBookedPerforms.length; },
      showStatusesSelect() { return !!(this.computedStatuses.length); },
      showTypesSelect() { return !!(this.computedTypes.length); },
      showLanguagesSelect() { return !!(this.languagesList.length) && this.isInterpretationView; },
      computedDepartmentsList() {
        return this.departmentsList.map((department) => {
          return {id: department.id, name: department.name};
        });
      },
      computedBookersList() {
        return this.bookersList.map((booker) => {
          return {
            id: booker.uid,
            name: booker.firstName || booker.lastName ? `${booker.firstName} ${booker.lastName}` : booker.email
          };
        });
      },
      computedBookedPerforms() {
        const performesArray = this.isInterpretationView ? this.bookedInterpreters : this.bookedTranslators;
        return performesArray.map(({uid, id, name}) => ({id: uid || id, name}));
      },
      computedStatuses() {
        const assignmentStatuses = [
          {id: 'published', name: this.$gettext('Received')},
          {id: 'accepted', name: this.$gettext('Accepted')},
          {id: 'in_progress', name: this.$gettext('Confirmed')},
          {id: 'finished', name: this.$gettext('Finished')},
          {id: 'closed', name: this.$gettext('Closed')},
          {id: 'cancelled', name: this.$gettext('Cancelled')}
        ];

        const projectStatuses = [
          {id: 'draft', name: this.$gettext('Draft')},
          {id: 'transferred', name: this.$gettext('Transferred')},
          {id: 'proactive_access', name: this.$gettext('Proactive access')},
          {id: 'invited', name: this.$gettext('Invited')},
          {id: 'applied', name: this.$gettext('Applied')},
          {id: 'accepted', name: this.$gettext('Accepted')},
          {id: 'rejected', name: this.$gettext('Rejected')},
          {id: 'in_progress', name: this.$gettext('In progress')},
          {id: 'review_requested', name: this.$pgettext('translation', 'Review requested')},
          {id: 'changes_required', name: this.$pgettext('translation', 'Changes required')},
          {id: 'finished', name: this.$gettext('Finished')},
          {id: 'closed', name: this.$gettext('Closed')},
        ];

        return this.isInterpretationView ? assignmentStatuses : projectStatuses;
      },
      computedTypes() {
        const assignmentTypes = [
          {id: 'phone', name: this.$gettext('Phone')},
          {id: 'in_person', name: this.$gettext('In-person')},
          {id: 'video', name: this.$gettext('Video')},
          {id: 'videoroom', name: this.$gettext('Video room')}
        ];

        const projectTypes = [
          {id: 'translation', name: this.$pgettext('translation', 'Translation')},
          {id: 'correction', name: this.$gettext('Correction')},
          {id: 'in_person', name: this.$gettext('In-person')},
        ];

        return this.isInterpretationView ? assignmentTypes : projectTypes;
      }
    },
    watch: {
      filterValues: {
        deep: true,
        handler() {
          this.setChartProgress(true);
          this.fetchStatistics({chartId: this.$route.params.chartId, scope: this.$route.query.view});
          if (this.isInterpretationView) {
            this.fetchBookedInterpreters();
          } else {
            this.fetchBookedTranslators();
          }
        }
      }
    },
    methods: {
      ...mapActions('StatisticsStore', [
        'fetchStatistics',
        'fetchBookedInterpreters',
        'fetchBookedTranslators'
      ]),
      ...mapMutations('StatisticsStore', [
        'setDepartments',
        'setBookers',
        'setInterpreters',
        'setTypes',
        'setStatuses',
        'setLanguages',
        'setDateRange',
        'setSourceLanguage',
        'setTargetLanguage',
        'setChartProgress'
      ]),
      getRangeObject({startDate, endDate, mutationFunc, descriptionFrom, descriptionTo}) {
        return {
          descriptionFrom,
          descriptionTo,
          startDate: {
            ...this.getDateObject(startDate),
            dateChanged: (startDateMoment) => {
              const endDateMoment = this.$moment(endDate, 'YYYY-MM-DD');
              this.setStartDate(startDateMoment, endDateMoment, mutationFunc);
            }
          },
          endDate: {
            ...this.getDateObject(endDate),
            dateChanged: (endDateMoment) => {
              const startDateMoment = this.$moment(startDate, 'YYYY-MM-DD');
              this.setEndDate(startDateMoment, endDateMoment, mutationFunc);
            }
          }
        };
      },
      getDateObject(date) {
        return {
          defaultMonth: this.$moment(date, 'YYYY-MM-DD').month() + 1,
          defaultYear: this.$moment(date, 'YYYY-MM-DD').year()
        };
      },
      setStartDate(startDateMoment, endDateMoment, mutationFunc) {
        const startDate = startDateMoment.format('YYYY-MM-DD');
        const endDate = startDateMoment.isBefore(endDateMoment)
          ? endDateMoment.format('YYYY-MM-DD')
          : startDateMoment.add(1, 'months').endOf('month').format('YYYY-MM-DD');
        mutationFunc({startDate, endDate});
      },
      setEndDate(startDateMoment, endDateMoment, mutationFunc) {
        const startDate = this.$moment(startDateMoment).format('YYYY-MM-DD');
        const endDate = endDateMoment.format('YYYY-MM-DD');
        mutationFunc({startDate, endDate});
      }
    }
  };
</script>

<style scoped>
  .filter-bar__fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px -15px;
  }

  .filter-bar__field {
    width: calc(25% - 10px);
    max-width: 300px;
    margin: 0 5px 15px;
  }

  .filter-bar__field--wrapper {
    display: flex;
    justify-content: space-between;
  }

  .filter-bar__field-item {
    width: calc(50% - 5px);
  }

  @media (max-width: 767px) {
    .filter-bar__field {
      width: calc(50% - 10px);
      max-width: 100%;
    }
  }

  @media (max-width: 500px) {
    .filter-bar__field {
      width: 100%;
    }
  }
</style>
